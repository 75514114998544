import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import { adminRoot } from './constants/config'
import { getCurrentUser } from './utils'

let permissoes = []
let funcionalidades = []
try {
  if (getCurrentUser()) {
    permissoes = getCurrentUser().perfil.permissoes
    getCurrentUser().perfil.funcionalidades.forEach(item => {
      funcionalidades.push(parseInt(item.id))
    })
  }
} catch (e) {
  console.error(e)
}

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "user" */ './pages/Login'),
    redirect: '/login',
    children: [
      {
        path: 'login',
        component: () =>
          import(/* webpackChunkName: "user" */ './pages/Login/Login.vue'),
        name: 'login'
      },
      {
        path: 'nova-senha/user/:id',
        component: () =>
          import(/* webpackChunkName: "user" */ './pages/Login/TrocarSenha'),
        name: 'novaSenha'
      }
    ]
  },
  {
    path: adminRoot,
    component: () => import('./pages'),
    redirect: `${adminRoot}/dashboard`,
    beforeEnter: (to, from, next) => {
      if (localStorage.user) {
        return next()
      } else {
        next({ name: 'login' })
      }
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('./pages/views/Dashboard.vue')
      },
      {
        path: 'ordem-servico',
        component: () => import('./pages/views/Ordem'),
        beforeEnter: (to, from, next) => {
          if (
            permissoes.find(s => parseInt(s.id) === 1) ||
            permissoes.length === 0
          ) {
            next()
          } else {
            next({ name: 'cliente.listagem' })
          }
        },
        children: [
          {
            path: '/',
            name: 'ordem.listagem',
            component: () => import('./pages/views/Ordem/Listagem')
          },
          {
            path: 'impressao/:id',
            name: 'ordem.impressao',
            component: () => import('./pages/views/Ordem/Impressao')
          },
          {
            path: 'envelopamento/:id',
            name: 'ordem.envelopamento',
            component: () => import('./pages/views/Ordem/Envelopamento')
          },
          {
            path: 'concluidos/:id/caixa/:caixa',
            name: 'envelopamento.concluido',
            component: () =>
              import('./pages/views/Ordem/EnvelopamentoConcluidos')
          },
          {
            path: 'producao/:id/caixa/:caixa',
            name: 'envelopamento.producao',
            component: () => import('./pages/views/Ordem/EnvelopamentoProducao')
          }
        ]
      },
      {
        path: 'clientes',
        component: () => import('./pages/views/Cliente'),
        beforeEnter: (to, from, next) => {
          if (
            permissoes.find(s => parseInt(s.id) === 2) ||
            permissoes.length === 0
          ) {
            next()
          } else {
            next({ name: 'usuario.listagem' })
          }
        },
        children: [
          {
            path: '/',
            name: 'cliente.listagem',
            component: () => import('./pages/views/Cliente/Listagem')
          },
          {
            path: 'detalhes/:id',
            name: 'cliente.formulario',
            component: () => import('./pages/views/Cliente/Formulario')
          }
        ]
      },
      {
        path: 'usuarios',
        component: () => import('./pages/views/Usuarios'),
        beforeEnter: (to, from, next) => {
          if (
            permissoes.find(s => parseInt(s.id) === 3) ||
            permissoes.length === 0
          ) {
            next()
          } else {
            next({ name: 'unauthorized' })
          }
        },
        children: [
          {
            path: '/',
            name: 'usuario.listagem',
            component: () => import('./pages/views/Usuarios/Listagem')
          },
          {
            path: 'detalhes/:id',
            name: 'usuario.formulario',
            component: () => import('./pages/views/Usuarios/Formulario')
          },
          {
            path: 'novo',
            name: 'usuario.novo',
            component: () => import('./pages/views/Usuarios/Formulario')
          }
        ]
      },
      {
        path: 'impressao',
        name: 'producao.listagem',
        component: () => import('./pages/views/Ordem/Listagem')
      }
    ]
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () =>
      import(/* webpackChunkName: "error" */ './pages/views/NaoAutorizado')
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "error" */ './pages/views/Error')
  }
]
const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
